<template>
  <b-modal
    id="signature-modal"
    title="Signature"
    size="lg"
    footer-class="justify-content-center"
    @ok="handleOk"
  >
    <b-container>
      <masthead title="Signatures" :show-search="false" />
      <b-row>
        <label> Select company</label>
        <b-form-select v-model="selectedCompany" :options="mappedCompanies" />
      </b-row>
      <br>
      <div v-if="selectedCompany">
        <b-button
          size="sm"
          variant="primary"
          aria-label="create new signature button"
          @click="showSignaturePad = !showSignaturePad"
        >
          Create new signature
        </b-button>
      </div>
      <br>
      <div v-if="showSignaturePad">
        <b-row>
          <b-col>
            <b-form-group label="First name">
              <b-form-input v-model="firstName" :state="isValidInput(firstName)" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Last name">
              <b-form-input v-model="lastName" :state="isValidInput(lastName)" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Reason">
              <b-form-select
                v-model="reason"
                :options="mappedSignatureTypes"
                :state="isValidInput(reason)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="shadow border border-info rounded">
          <vue-signature-pad
            ref="signaturePad"
            width="100%"
            height="15%"
          />
        </b-row>
        <b-row class="float-right">
          <b-button
            class="mb-5 mr-3"
            variant="secondary"
            aria-label="clear button"
            @click="clearSignature()"
          >
            Clear
          </b-button>
          <b-button
            class="mb-5"
            variant="primary"
            aria-label="save button"
            @click="checkIfSignatureIsEmpty()"
          >
            Save
          </b-button>
        </b-row>
      </div>
      <br>
      <b-row v-if="signatures.length > 0">
        <b-col>
          <h3>Signatures</h3>
          <b-row v-for="signature in signatures" :key="signature.id" class="mt-3">
            <b-col>
              {{ capitalizeFirstLetter(signature.first_name) }}
              {{ capitalizeFirstLetter(signature.last_name) }} signed for
              {{ signature.signature_type }}
            </b-col>
            <b-col>
              <b-img thumbnail fluid :src="signature.file.join()" alt="Can't find image" />
            </b-col>
            <b-col>
              <b-button
                size="sm"
                variant="danger"
                aria-label="delete button"
                @click="deleteSignatures({signatureId: signature.id, companyId: companyId})"
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Masthead from '@/components/shared/Masthead'
import VueSignaturePad from 'vue-signature-pad'
import { makeToastMixin } from '@/mixins/makeToastMixin'

  export default {
    name: "SignatureModal",
    components: { Masthead, VueSignaturePad },
    mixins: [makeToastMixin],
    props: {
      companyId: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selectedCompany: null,
        showSignaturePad: false,
        firstName: '',
        lastName: '',
        reason: '',
      }
    },
    computed: {
      ...mapGetters('companies', ['companies']),
      ...mapGetters('signatures', ['signatures', 'signatureTypes']),
      mappedCompanies() {
        return this.companies.map(company => {
          return { value: company.id, text: company.name }
        })
      },
      mappedSignatureTypes() {
        return Object.keys(this.signatureTypes).map(type => {
          return { value: type, text: type }
        })
      },
    },
    async mounted() {
      await this.getCompany()
      await this.getSignatureTypes()
      await this.getSignatures({ companyId: this.companyId })
      await this.loadMore()
    },
    methods: {
      ...mapActions('companies', ['loadMore']),
      ...mapActions('signatures', [
        'getSignatures',
        'getSignatureTypes',
        'deleteSignatures',
        'saveSignature',
      ]),
      getCompany() {
        this.companyId ? this.selectedCompany = this.companyId : null
      },
      capitalizeFirstLetter(string) {
        return _.capitalize(string)
      },
      async checkIfSignatureIsEmpty() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        if (isEmpty || !this.isAllInputValid()) {
          this.errorToast('Missing Information', 'The signature form is missing required information')
        } else {
          await this.saveSignature({
            companyId: this.selectedCompany,
            data: data,
            firstName: this.firstName,
            lastName: this.lastName,
            reason: this.reason,
          }).then(() => {
            this.showSignaturePad = false
            this.$emit('signing-complete')
          })
        }
      },
      clearSignature() {
        this.$refs.signaturePad.clearSignature()
      },
      isValidInput(input) {
        return input.length > 0
      },
      isAllInputValid() {
        return (
          this.isValidInput(this.firstName) &&
          this.isValidInput(this.lastName) &&
          this.isValidInput(this.reason)
        )
      },
      handleOk() {
        this.$emit('signing-complete')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal-title {
    margin-top: -1em;
    margin-bottom: 1em;
    text-align: center;
  }
</style>
